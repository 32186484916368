import "maplibre-gl/dist/maplibre-gl.css";

import maplibregl, { MapOptions } from "maplibre-gl";

class Map {
  static selector() {
    return "#map";
  }

  node: HTMLElement;
  style: string;
  latitude: number;
  longitude: number;
  geojson: any;

  constructor(node) {
    this.node = node;
    this.style = "https://maps.terredeliens.org/styles/tdl/style.json";
    this.latitude = JSON.parse(
      document.getElementById("latitude")?.textContent || null,
    );
    this.longitude = JSON.parse(
      document.getElementById("longitude")?.textContent || null,
    );
    this.init();
  }

  init() {
    if (document.getElementById("geojson")) {
      this.geojson = JSON.parse(
        document.getElementById("geojson")?.textContent || null,
      );
    }
    if (!this.latitude || !this.longitude) {
      return;
    }

    const mapConfig: MapOptions = {
      container: this.node,
      style: this.style,
      center: [this.longitude, this.latitude],
      zoom: 6,
      minZoom: 4.7,
      maxZoom: 11,
      maxBounds: new maplibregl.LngLatBounds(
        new maplibregl.LngLat(-9, 41),
        new maplibregl.LngLat(13, 52),
      ),
    };

    const map = new maplibregl.Map(mapConfig);

    const departmentsFeatures = this.geojson
      ? this.geojson.map(({ id, data }) => data)
      : null;
    const farmsFeatures = [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [this.longitude, this.latitude],
        },
        properties: { type: "Farm" },
      },
    ];

    map.on("load", function () {
      map.addSource("farms", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: farmsFeatures,
        },
      });

      if (departmentsFeatures) {
        map.addSource("departments", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: departmentsFeatures,
          },
        });

        map.addLayer({
          id: "departments-borders",
          type: "fill",
          source: "departments",
          paint: {
            "fill-color": "#A6B800",
            "fill-outline-color": "#ffffff",
            "fill-opacity": 1,
          },
        });

        // map.addLayer({
        //   id: "departments-labels",
        //   type: "symbol",
        //   source: "departments",
        //   layout: {
        //     "text-field": ["get", "nom"],
        //     "text-variable-anchor": ["top", "bottom", "left", "right"],
        //     "text-radial-offset": 0.5,
        //     "text-justify": "auto",
        //   }
        // });
      }

      map.addLayer({
        id: "farm",
        type: "circle",
        source: "farms",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-radius": 6,
          "circle-color": "#B42222",
        },
      });
    });
  }
}

export default Map;
