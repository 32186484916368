export default () => ({
  html: document.querySelector(".iframe-container iframe"),
  initialize() {
    console.log("Script d'iframe chargé et exécuté");
    this.html = document.querySelector(".iframe-container iframe");
    if (this.html) {
      console.log("Iframe trouvée :", this.html);
      iFrameResize({}, this.html);
    } else {
      console.warn("Aucune iframe trouvée dans .iframe-container");
    }
  },
});
